import React from 'react';
import { Row, Col, Input, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { SearchForm, ViewTable, Layout, Button } from '@/components';
import FeeCreateModal from './FeeCreateModal';
import { LibAuthCode } from 'lib/Auth';
import WebApi from '@/web/api';

const { RangePicker } = DatePicker;

export default class  FinanceFeePage extends React.PureComponent<RouterPropsType> {
  private table = React.createRef<ViewTable>()
  private create = React.createRef<FeeCreateModal>()
  
  private queryParams = {
    bookTypeList: [ '20', '21', '22', '23', '24', '25' ],
    targetName: '',
    dateRange: []
  }

  state = {
    visible: false
  }

  findAllPayment = async () => {
    this.table.current?.reset(this.queryParams)
  }

  render() {
    return <Layout.Page>
      <Layout.Toolbar title="费用支出单">
        <Button authCode={LibAuthCode.book.create} type="primary"
          onClick={() => {
            this.setState({
              visible: true,
            });
          }}>新增费用支出单</Button>
        <Button.Refresh onClick={() => {
          this.table.current?.refresh();
        }}/>
      </Layout.Toolbar>
      <SearchForm.Row>
        <SearchForm.Item label="收款单位">
          <SearchForm.Search
            placeholder="填写收款单位"
            onChange={e => {
              this.queryParams.targetName = e.target.value;
            }}
            onSearch={(v) => {
              this.queryParams.targetName = v;
              this.findAllPayment();
            }}
          />
        </SearchForm.Item>
        <SearchForm.Item label="付款时间">
          <RangePicker style={{width: '100%'}} allowClear disabledDate={current => {
            return current && current > dayjs();
          }} onChange={(dates, dateStrings) => {
            this.queryParams.dateRange = dateStrings as any;
            this.findAllPayment();
          }}/>
        </SearchForm.Item>
      </SearchForm.Row>
      
      <ViewTable
        flex1
        ref={this.table}
        rowKey={row => row.bookId}
        fetch={data => {
          return WebApi.book_paging({...data, ...this.queryParams})
        }}
        columns={[
          {
            title: '单号',
            align: 'center',
            width: 200,
            dataIndex: 'bookId',
          },
          {
            title: '付款时间',
            align: 'center',
            width: 200,
            dataIndex: 'datetime',
          },
          {
            title: '收款单位',
            width: 200,
            dataIndex: 'targetName',
          },
          {
            title: '付款金额',
            align: 'right',
            width: 120,
            dataIndex: 'actualAmount',
          },
          {
            title: '费用类型',
            align: 'center',
            dataIndex: 'bookTypeDesc',
            width: 120,
          },
          // {
          //   title: '创建时间',
          //   align: 'center',
          //   width: 200,
          //   dataIndex: 'createdAt',
          // },
          {
            title: '备注说明',
            width: 300,
            dataIndex: 'remark',
          },
        ]}
      />

      <FeeCreateModal visible={this.state.visible} onOk={() => this.table.current?.refresh()} onClose={() => this.setState({visible: false})}/>
    </Layout.Page>
  }
}
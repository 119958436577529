import React from "react";
import { Form, Row, Col, Select, Button, Input, DatePicker } from 'antd';
import { FormInstance } from "antd/lib/form";
import { hideLoading, showLoading } from "@/components/message/message";
import dayjs from 'dayjs';
import { Dialog } from "@/components";
import WebApi from "@/web/api";
import LibEnum from "lib/enum";

interface IProps {
  visible: boolean;
  onClose(): void;
  onOk(): void;
}
export default class FeeCreateDialog extends React.PureComponent<IProps> {
  private form = React.createRef<FormInstance>()

  render() {
    return <Dialog
      full={false}
      title="新增费用支出单"
      visible={this.props.visible}
      onOk={this.onOk}
      onCancel={this.props.onClose}
    >
      <div>
        <Form labelCol={{span: 4}} ref={this.form}>
          <Form.Item label="收款单位" name="targetName" rules={[{
            required: true,
            message: '请填写收款单位'
          }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="付款金额" name="actualAmount" validateFirst
            rules={[
              {
                required: true,
                message: '请填写付款金额'
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (Number(value) === Number(Number(value).toFixed(2))) {
                    return Promise.resolve();
                  }
                  return Promise.reject('请填写正确的金额，支持小数点后2位');
                },
              }),
            ]}
          >
            <Input placeholder=""/>
          </Form.Item>
          <Form.Item label="费用类型" name="bookType" rules={[{
            required: true,
            message: '请选择费用类型'
          }]}>
            <Select>
              <Select.Option value={LibEnum.BOOK_TYPE.FEE_OIL.value}>加油</Select.Option>
              <Select.Option value={LibEnum.BOOK_TYPE.FEE_SALARY.value}>工资</Select.Option>
              <Select.Option value={LibEnum.BOOK_TYPE.FEE_ELECTRICITY.value}>电费</Select.Option>
              <Select.Option value={LibEnum.BOOK_TYPE.FEE_WATER.value}>水费</Select.Option>
              <Select.Option value={LibEnum.BOOK_TYPE.FEE_OTHER.value}>其他费用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="付款日期" name="payTime" rules={[
            {
              required: true,
              message: '请选择付款日期'
            },
          ]}>
            <DatePicker showTime placeholder="" style={{width: '100%'}}/>
          </Form.Item>
          <Form.Item label="付款备注">
            <Input.TextArea maxLength={500} rows={4} placeholder="最多500字"/>
          </Form.Item>
        </Form>
      </div>
    </Dialog>
  }

  private onOk = async () => {
    let values;
    try {
      values = await this.form.current?.validateFields();
    } catch (e) {
      console.log(e);
      return;
    }

    showLoading(0);

    WebApi.book_create({
      targetName: values.targetName,
      bookType: values.bookType,
      actualAmount: values.actualAmount,
      shouldAmount: values.actualAmount,
      datetime: dayjs(values.payTime).format('YYYY-MM-DD HH:mm:ss'),
      remark: values.remark,
    })
    .then(() => {
      showSuccess.save();
      this.props.onOk();
      this.props.onClose();
    })
    .finally(() => {
      hideLoading();
    })
  }
}